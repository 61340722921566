import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import LandingHero from '../components/LandingHero';
import { loginURL } from '../utils/constants';


export const Home = ({type = 'writer'}) => {
	console.log("type===", type)
	return (
		<Container maxWidth='md'>
		<Box sx={{mt: 10, display:'flex', justifyContent: 'center', flexDirection:'column'}}>
		<Typography
		sx={{
			color: '#090446',
			fontSize: '64px',
			fontStyle: 'normal',
			fontWeight: 600,
			lineHeight: '80px',
			textAlign: 'center'
		}}>
			{type === 'writer' ? 'Write Better' : 'Ace your college essays'}
		</Typography>

		<Button href={loginURL(type)} variant="contained" sx={{mt:1, alignSelf:'center', py: 1, px: 3, width:"100%",maxWidth:'240px', backgroundColor:'#5551FF', color: '#FFFFFF'}}>
			Get started
		</Button>

		<Box sx={{mt: 13, alignSelf:'center'}}>

		<LandingHero />
		</Box>

		</Box>

	
		</Container>
	);
};
