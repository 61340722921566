import { IconButton, AppBar, Toolbar, Typography, SvgIcon, Box, Button, useMediaQuery } from "@mui/material";

import LogoIcon from "./LogoIcon";
import { LIGHT_MODE_THEME, loginURL } from "../utils/constants";
import MenuIcon from '@mui/icons-material/Menu';
import { getAppTheme } from "../styles/theme";
import LogoSmallIcon from "./LogoSmallIcon";
import { useState } from "react";
import AppDrawer from "./Drawer";


export function AppToolbar(props: any) {
  const {  sx,type, ...other } = props;
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const [open, setOpen] = useState(false)


  return (
  <AppBar
      color={"transparent"}
      elevation={0}
      {...other}
    >
       <Toolbar sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobileView ? 'none' : 'space-between'
       }}>
            {isMobileView && (<IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() =>setOpen(x => !x)}
          >
            <MenuIcon />
          </IconButton>)}
      {!isMobileView &&  <IconButton  sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }} href="/"><LogoIcon /></IconButton>}
       {isMobileView && <IconButton 
       sx={{
        "&.MuiButtonBase-root:hover": {
          bgcolor: "transparent"
        }
      }}
       href="/"><LogoSmallIcon /></IconButton> }
        
     {!isMobileView &&   <Box>
          <Button variant="text" size='large' color="primary" href={type === 'writer' ? "/#/college-essays": '/'}>
            <Typography sx={{fontWeight:600}}>

            {type === "writer" ? "College Essay" : "Writer"}
            </Typography>
            </Button>
          <Button variant="text" size='large' color="primary" href={(loginURL(type))} >
          <Typography sx={{fontWeight:700}}>
            Log in
            </Typography>
            </Button>
          <Button variant="text" size='large' color="primary" href={loginURL(type)}>
          <Typography sx={{fontWeight:700}}>
            TRY FOR FREE
            </Typography>
            </Button>

        </Box>}
        {/* </Box> */}
        {isMobileView && <AppDrawer loginURL={loginURL} type={type} open={open} setOpen={setOpen} />}
    </Toolbar>
    </AppBar>
  );
}
