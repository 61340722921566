import {  createTheme, responsiveFontSizes } from '@mui/material';

import { DARK_MODE_THEME, LIGHT_MODE_THEME } from '../utils/constants';

export const getAppTheme = (mode: typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME) => {
	let theme = createTheme({
		palette: {
			mode,
			primary: {
				main: '#090446'
			  }
		},
		components: {
			MuiButton: {
			  styleOverrides: {
				root: {
				  textTransform: "none"
				}
			  }
			}
		  },
		  typography: {
			"fontFamily": `"Inter", "Helvetica", "Arial", sans-serif`,
		
		   }
	});
	theme = responsiveFontSizes(theme);
	return theme;
};
