import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { HashRouter, Route, Switch } from 'react-router-dom';

import { AppContext, ThemeModeContext } from './contexts';
import { getAppTheme } from './styles/theme';
import { LIGHT_MODE_THEME } from './utils/constants';
import { Home } from './pages/Home';
import { Layout } from './components/Layout';

function App() {
	const theme =  getAppTheme(LIGHT_MODE_THEME);

	return (
		<AppContext.Provider value={{}} >
			<ThemeModeContext.Provider value={{}}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<HashRouter>
						
						{/* <Switch> */}
						<Route exact  path="/college-essays" render={({match}) => 
						<Layout type="college-essays">
							<Home type="college-essays" />
						</Layout>
						
						} />

<Route exact path="/" render={({match}) => 
						<Layout type="writer">
							<Home type="writer" />
						</Layout>
						
						} />
						{/* <Route key="college-essays" path="/college-essays" component={Home} exact /> */}


							
						{/* </Switch> */}

					</HashRouter>
				</ThemeProvider>
			</ThemeModeContext.Provider>
		</AppContext.Provider>
	);
}

export default App;
