import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function LogoSmallIcon() {
  return (
    <SvgIcon sx={{height: 25, width: 27}}>
 <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M2.99927 10.2418C5.88623 12.0063 9.46363 9.75628 11.9642 7.12035C13.4056 5.60082 14.0525 3.42826 15.4435 1.85998C17.1571 -0.0716956 19.7077 0.142571 21.9236 0.956618C22.6161 1.21103 23.225 1.62891 23.749 2.15208L25.7764 0L24.5368 3.10297C24.5437 3.11239 24.5505 3.12181 24.5574 3.13123L26.7067 1.4634L24.7702 3.46267C25.1849 4.14439 25.505 4.90149 25.7283 5.67293C27.4044 11.4765 22.4808 16.6411 17.705 17.2699L16.7783 23.4238L16.7933 23.4255L16.8106 23.4038L16.809 23.4275L18.3006 23.5967L18.0042 24.3587L16.822 23.4644L17.7817 24.396L17.0763 24.7963L16.8026 23.5074L16.6976 24.7762L15.949 24.4685L16.759 23.4668L15.842 17.3715C15.3744 17.3801 14.9089 17.3777 14.4462 17.3621L13.5187 23.5213L13.5345 23.523L13.5526 23.5017L13.5502 23.5254L15.0413 23.6946L14.745 24.4566L13.5627 23.5623L14.5225 24.4943L13.8183 24.8946L13.5437 23.607L13.4396 24.8741L12.691 24.5664L13.5001 23.5656L12.5468 17.2273C11.4926 17.1122 10.45 16.9319 9.42164 16.6714C7.12661 16.0896 5.3698 15.4423 3.87706 13.6569C2.47113 11.976 1.19562 10.3028 0.308538 8.49936C1.45283 9.33594 2.06857 9.6727 2.99927 10.2418ZM21.9587 1.78132C20.8972 1.86899 20.0929 2.7363 20.1607 3.72077L21.9587 1.78132ZM13.5256 23.5344L13.5183 23.5287L13.5151 23.5471L13.5256 23.5344ZM16.7852 23.4365L16.7779 23.4308L16.7747 23.4484L16.7852 23.4365ZM18.1354 11.3053C17.6416 9.41337 15.2529 10.1025 14.0085 10.4019C11.8035 10.9325 10.037 12.2758 8.11262 13.4881C9.76162 16.5431 19.436 15.2174 18.1354 11.3053Z" fill="#090446"/>
</g>
<defs>
<clipPath id="clip0_212_14608">
<rect width="26.3981" height="24.8946" fill="white" transform="matrix(-1 0 0 1 26.7067 0)"/>
</clipPath>
</defs>
</svg>


    </SvgIcon>
  );
}