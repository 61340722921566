import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {  Typography } from '@mui/material';


const AppDrawer = ({open, setOpen, type, loginURL}:any) => {
    const drawerWidth = 240;

    return (
        <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        onBackdropClick={(x) => setOpen(!x)}
        anchor="left"
        open={open}

      >
     

        
        <List>
   
            <ListItem key={1} disablePadding>
              <ListItemButton href={type === 'writer' ? "/#/college-essays": '/'}>
              
                <Typography sx={{fontWeight:600}}>

            {type === "writer" ? "College Essay" : "Writer"}
            </Typography>
            
              </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem key={2} disablePadding>
              <ListItemButton  href={loginURL(type)}>
              
              <Typography sx={{fontWeight:700}}>
            Log in
            </Typography>
              </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem key={3} disablePadding>
              <ListItemButton  href={loginURL(type)}>
              
              <Typography sx={{fontWeight:700}}>
            TRY FOR FREE
            </Typography>
              </ListItemButton>
            </ListItem>
   
        </List>
     
      </Drawer>
    )
}
export default AppDrawer