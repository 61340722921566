import { FC, useState } from 'react';
import { Box } from '@mui/material';

import { AppToolbar } from '../AppToolbar';


export const Layout = ({ children, type='writer' }:any) => {
	const [open, setOpen] = useState(false);
	const toggleNavigation = () => setOpen((status) => !status);

	return (
		<div
		
		>
			<div
			
			>
				<Box component='header'>
					{/* <Header toggleNavigation={toggleNavigation} /> */}
					<AppToolbar type={type}  />
				</Box>

				{/* <Navigation open={open} handleClose={toggleNavigation} /> */}
				<Box component='main' sx={{ flexGrow: 1, p: 3, pt: 10 }}>
					{children}
				</Box>
			</div>
			{/* <Box component='footer'>
				<Footer />
			</Box> */}
		</div>
	);
};